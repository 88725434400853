import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

export default function Navbar() {
  return (
    <AppBar 
      position="fixed" 
      sx={{ 
        background: `linear-gradient(to right, 
          rgba(255, 255, 255, 0.95),
          rgba(255, 255, 255, 0.98)
        )`,
        backdropFilter: 'blur(8px)',
        boxShadow: '0 2px 12px rgba(0,0,0,0.03)',
        borderBottom: '1px solid rgba(0,0,0,0.05)',
        height: '80px',
        justifyContent: 'center'
      }}
    >
      <Toolbar sx={{ py: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <HomeIcon 
            sx={{ 
              color: '#FF5A5F',
              mr: 2,
              fontSize: 32,
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.1)'
              }
            }} 
          />
          <Typography 
            variant="h6" 
            component="div" 
            sx={{ 
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: '0.5px',
              color: '#2c2c2c',
              fontSize: '1.25rem',
              '& span': {
                color: '#FF5A5F'
              }
            }}
          >
            ANANA <span>PROPERTIES</span> INC.
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}