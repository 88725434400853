import { Box, Container, Typography, Grid, Chip, Button } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BusinessIcon from '@mui/icons-material/Business';
import HotelIcon from '@mui/icons-material/Hotel';
import EmailIcon from '@mui/icons-material/Email';

export default function Hero() {
  // Wrap specialties array in useMemo
  const specialties = useMemo(() => [
    'Real Estate Innovation', 
    'Modern Accommodation', 
    'Elevated Hospitality', 
    'Strategic Investment'
  ], []); // Empty dependency array since array content is static

  const [currentSpecialty, setCurrentSpecialty] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSpecialty((prev) => (prev + 1) % specialties.length);
    }, 4000);

    return () => clearInterval(timer);
  }, [specialties.length]); // Only depend on array length

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        background: `
          linear-gradient(135deg, 
            rgba(255, 90, 95, 0.08) 0%,
            rgba(255, 255, 255, 1) 100%
          )
        `,
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `
            radial-gradient(circle at 25% 25%, rgba(255, 90, 95, 0.05) 0%, transparent 40%),
            radial-gradient(circle at 75% 75%, rgba(255, 90, 95, 0.05) 0%, transparent 40%)
          `,
          zIndex: 1,
        },
        py: 8,
      }}
    >
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography 
              variant="h2" 
              gutterBottom 
              sx={{ 
                fontWeight: 800,
                color: '#2c2c2c',
                mb: 4, // Increased bottom margin
                mt: 12, // Added top margin to account for navbar
                fontSize: { xs: '2.5rem', md: '3.5rem' }, // Responsive font size
                lineHeight: 1.2,
                letterSpacing: '-0.02em',
                display: 'flex', // Added flex
                alignItems: 'baseline', // Align at text baseline
                gap: 2,
              }}
            >
              Shaping the Future of
              <span style={{ color: '#FF5A5F' }}>
                {specialties[currentSpecialty]}
              </span>
            </Typography>
            <Typography 
              variant="h5" 
              sx={{ 
                color: '#4a4a4a',
                mb: 4,
                maxWidth: '800px',
                '& span': {
                  color: '#FF5A5F',
                  fontWeight: 600
                }
              }}
            >
              Since 2014, fostering human growth through innovative real estate and hospitality experiences
            </Typography>
          </Grid>

          <Grid item xs={12} md={8}>
            <Typography 
              variant="body1" 
              sx={{ 
                color: '#6a6a6a',
                mb: 6,
                fontSize: '1.1rem',
                lineHeight: 1.7
              }}
            >
              At ANANA Properties Inc., continuous learning and improvement are the cornerstones of our success. 
              We believe in fostering human growth in every aspect of our business - from our innovative real estate 
              solutions to our exceptional hospitality services. Our commitment to development extends beyond our team 
              to deliver exceptional experiences that inspire growth and connection.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              {[
                { 
                  icon: <BusinessIcon sx={{ fontSize: 32, color: '#FF5A5F', mb: 2 }} />,
                  title: 'Real Estate Innovation',
                  desc: 'Leveraging cutting-edge technology and data-driven insights while fostering personal growth through our property management solutions. We create environments where both properties and people thrive.'
                },
                {
                  icon: <HotelIcon sx={{ fontSize: 32, color: '#FF5A5F', mb: 2 }} />,
                  title: 'Elevated Hospitality',
                  desc: 'Creating spaces where meaningful connections flourish. Our approach to hospitality focuses on personal development alongside exceptional service, ensuring growth opportunities for both guests and team members.'
                },
                {
                  icon: <MonetizationOnIcon sx={{ fontSize: 32, color: '#FF5A5F', mb: 2 }} />,
                  title: 'Strategic Investment',
                  desc: 'Investing in both properties and people. Our strategic approach combines sustainable real estate growth with continuous personal development, creating lasting value for all stakeholders.'
                }
              ].map((item, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Box 
                    sx={{ 
                      p: 3,
                      height: '100%', // Ensure all boxes are same height
                      minHeight: '240px', // Set minimum height
                      display: 'flex',
                      flexDirection: 'column',
                      background: '#ffffff',
                      borderRadius: 2,
                      transition: 'all 0.3s ease',
                      boxShadow: '0 4px 15px rgba(0,0,0,0.05)',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 8px 20px rgba(255,90,95,0.15)',
                        borderColor: '#FF5A5F'
                      }
                    }}
                  >
                    <Box sx={{ color: '#2c2c2c', mb: 2 }}>
                      {item.icon}
                    </Box>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        color: '#2c2c2c', 
                        mb: 2,
                        flexGrow: 0 // Prevent title from stretching
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: '#6a6a6a',
                        flexGrow: 1 // Allow description to fill remaining space
                      }}
                    >
                      {item.desc}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mt: 6 }}>
            <Typography variant="subtitle1" sx={{ color: '#2c2c2c', mb: 2 }}>
              Our Specialties
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {specialties.map((specialty) => (
                <Chip
                  key={specialty}
                  label={specialty}
                  sx={{
                    color: '#484848',
                    backgroundColor: '#ffffff',
                    border: '1px solid #e0e0e0',
                    '&:hover': {
                      backgroundColor: 'rgba(255,90,95,0.08)',
                      borderColor: '#FF5A5F',
                    },
                    '& .MuiChip-label': { 
                      px: 2,
                      py: 0.75
                    },
                  }}
                />
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ mt: 8, textAlign: 'center' }}>
            <Typography variant="h6" sx={{ color: '#2c2c2c', mb: 2 }}>
              Get in Touch
            </Typography>
            <Button
              variant="outlined"
              href="mailto:info@ananaproperties.com"
              startIcon={<EmailIcon />}
              sx={{ 
                mt: 2,
                px: 4,
                py: 1.5,
                borderRadius: 2,
                borderWidth: '2px',
                borderColor: '#e0e0e0',
                color: '#4a4a4a',
                backgroundColor: '#ffffff',
                fontSize: '1.1rem',
                textTransform: 'none',
                transition: 'all 0.3s ease',
                '&:hover': {
                  borderColor: '#2c2c2c',
                  backgroundColor: '#2c2c2c',
                  color: '#ffffff',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                }
              }}
            >
              info@ananaproperties.com
            </Button>
          </Grid>
          
        </Grid>
      </Container>
    </Box>
  );
}